<template>
<v-main class="text-center">
    <v-img :src="logo" max-height="50px" contain class="mt-12"/>
    <h5>{{ $appName }}</h5>
        <v-card
        class="mx-auto mt-12 px-12"
        max-width="600px"
        rounded
        :loading="loading"
        > 
            <v-container class="py-8">
                <h3>Reset password</h3>
                    <v-form 
                        ref="password_form"
                        class="mt-8"
                        @submit.prevent="onResetPassword"
                        >
                        <v-text-field
                            label="Email"
                            v-model="email"
                            round
                            outlined
                            :rules="rules.user.email"
                            />
                        <v-alert
                            dense
                            v-if="success"
                            type="success"
                            >Reset email sent</v-alert>
                        <v-btn
                            type="submit"
                            color="primary"
                            rounded
                            large
                            class="mt-6"
                            :loading="loading"
                            >
                            Reset password
                        </v-btn>
                        <v-container class="mt-6">
                            <v-btn 
                                text
                                small
                                block
                                color="primary"
                                @click="$router.push('/signup')"
                                >
                                Sign up
                            </v-btn>
                            <v-btn 
                                text
                                block
                                small
                                class="mt-6"
                                @click="$router.push('/signin')"
                                >
                                Log in
                            </v-btn>
                        </v-container>
                    </v-form>
            </v-container>
        </v-card>
        <v-footer class="text-caption" width="100%" absolute color="primary">
            <span class="white--text mx-auto">© {{ new Date().getFullYear() }} Zodiapps AB</span>
        </v-footer>
</v-main>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import rules from "@/utils/rules"

export default {
  data() {
    return {
      logo: require('@/assets/favicon-192.png'),
      loading: false,
      email: '',
      success: false,
      rules,
    }
  },
  methods: {
      async onResetPassword(){
        this.email = this.email.toLowerCase().replace(/ /gi, '')
        if(!this.$refs.password_form.validate()) return
        try {
            this.loading = true
            const auth = firebase.auth()
            await auth.sendPasswordResetEmail(this.email)
            this.success = true
            this.loading = false
        } catch (e) {
            this.$store.commit('app/SET_SNACK_BAR', 'No account registered on this email')
            this.loading = false
        }
      }
  },  
}
</script>
